import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'source', 'display']
  timeout = 2000

  async copy () {
    const text = this.inputTarget instanceof HTMLInputElement
      ? this.inputTarget.value
      : this.inputTarget.textContent

    await navigator.clipboard.writeText(text)
    this.#toggleUIonCopy()
  }

  #toggleUIonCopy () {
    const icons = {
      default: this.element.querySelector('.default-icon'),
      success: this.element.querySelector('.success-icon')
    }

    icons.default.hidden = true
    icons.success.hidden = false

    setTimeout(() => {
      icons.default.hidden = false
      icons.success.hidden = true
    }, this.timeout)

    // The stimulus eslint plugin has a bug where it doesn't account for `has[Name]Target` properties
    // eslint-disable-next-line stimulus/unused-targets
    if (this.hasSourceTarget) {
      for (const display of this.displayTargets) {
        display.hidden = true
      }
      for (const source of this.sourceTargets) {
        source.hidden = false
      }

      setTimeout(() => {
        for (const source of this.sourceTargets) {
          source.hidden = true
        }
        for (const display of this.displayTargets) {
          display.hidden = false
        }
      }, this.timeout)
    }
  }
}
