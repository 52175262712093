import { Controller } from '@hotwired/stimulus'
import { debounce } from '../utils'

const MIN_QUERY_LENGTH = 2

export default class extends Controller {
  static targets = ['input', 'results', 'container', 'hiddenInput',
    'autocompleteItemTemplate', 'selectedItemTemplate', 'noResultsTemplate']

  static values = {
    autoCompleteUrl: String
  }

  #selectedIds = new Set()

  initialize () {
    this.search = debounce(this.search.bind(this), 300)
  }

  connect () {
    this.#selectedIds = this.#parseSelectedIds()
  }

  async search (event) {
    const query = event.target.value.trim()

    if (query.length < MIN_QUERY_LENGTH) {
      this.#hideResults()
      return
    }

    try {
      const response = await fetch(`${this.autoCompleteUrlValue}?query=${encodeURIComponent(query)}`)
      const data = await response.json()
      if (data.length === 0) {
        this.#showNoResults()
      } else {
        this.#showResults(data)
      }
    } catch (error) {
      console.error('Search failed:', error)
    }
  }

  select (event) {
    const id = event.currentTarget.dataset.id

    if (!this.#selectedIds.has(id)) {
      this.#selectedIds.add(id)
      this.#addChip(id, event.currentTarget.dataset.name)
      this.#updateHiddenInput()
    }

    this.inputTarget.value = ''
    this.#hideResults()
    this.inputTarget.focus()
  }

  remove (event) {
    this.#selectedIds.delete(event.currentTarget.dataset.id)
    event.currentTarget.parentElement.remove()
    this.#updateHiddenInput()
  }

  #parseSelectedIds () {
    return new Set(this.hiddenInputTarget.value.split(',').filter(id => id.length > 0))
  }

  #updateHiddenInput () {
    this.hiddenInputTarget.value = [...this.#selectedIds].join(',')
  }

  #showResults (data) {
    const filteredResults = data.filter(item => !this.#selectedIds.has(item.id.toString()))
    this.resultsTarget.innerHTML = filteredResults.map(item => this.#resultTemplate(item)).join('')

    if (this.inputTarget === document.activeElement) {
      this.resultsTarget.classList.remove('hidden')
    }
  }

  #showNoResults () {
    this.resultsTarget.innerHTML = this.noResultsTemplateTarget.cloneNode(true).innerHTML
    this.resultsTarget.classList.remove('hidden')
  }

  #resultTemplate (item) {
    let template = this.autocompleteItemTemplateTarget.cloneNode(true).innerHTML
    template = template.replaceAll('$itemId$', item.id).replaceAll('$itemName$', item.name)
    template = (item.description)
      ? template.replaceAll('$itemDescription$', item.description)
      : template.replaceAll('$itemDescription$', '')

    return template
  }

  #hideResults () {
    this.resultsTarget.classList.add('hidden')
  }

  #addChip (id, name) {
    let chipHTML = this.selectedItemTemplateTarget.cloneNode(true).innerHTML
    chipHTML = chipHTML.replaceAll('$itemId$', id).replaceAll('$itemName$', name)

    const chipElement = document.createElement('div')
    chipElement.innerHTML = chipHTML

    this.containerTarget.insertBefore(chipElement, this.inputTarget)
  }
}
